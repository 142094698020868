import axios from '@service'
import { GET_MARGIN_PAYMENT_INFO_IN_FILLING } from './api'
import { HTTP_ERR_SUCCESS } from '@constant/api_status'
import { ERROR_DEFAULT_HTTP_REQUEST_FAIL } from '@constant/error'

// 获取缴纳备案中保证金缴纳信息
export default async function getMarginPaymentInfoService(payload = {}, config = {}) {
    const param = {
        ...GET_MARGIN_PAYMENT_INFO_IN_FILLING,
        ...config,
        params: payload
    }

    const { data } = await axios(param)

    if (data.code === HTTP_ERR_SUCCESS) {
        return data.result || {}
    } else {
        throw new Error(data.errorMessage || ERROR_DEFAULT_HTTP_REQUEST_FAIL)
    }
}
