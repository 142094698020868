<template>
    <a-descriptions :column="1">
        <template v-for="(item, index) in failureMsgList">
            <a-descriptions-item :key="index" :label="getLabel(item)">
                {{ getText(item) }}
            </a-descriptions-item>
        </template>
    </a-descriptions>
</template>
<script>
import { Descriptions as ADescriptions } from 'ant-design-vue'

import PropTypes from 'ant-design-vue/es/_util/vue-types'

export default {
    name: 'FillingFailureMsg',
    components: {
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item
    },
    props: {
        failureMsgs: PropTypes.arrayOf([PropTypes.string])
    },
    computed: {
        failureMsgList() {
            return this.failureMsgs.map(item => item.split(':'))
        }
    },
    methods: {
        getText([label, text] = []) {
            return text || label
        },
        getLabel([label, text] = []) {
            return text ? label : undefined
        }
    }
}
</script>
