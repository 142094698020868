<template>
    <custom-modal
        width="80%"
        title="保证金缴纳备案"
        :visible="visible"
        class="initiate-filling-modal"
        @on-close="handleClose"
    >
        <modal-content :id="id" @on-ok="handleOk" />

        <template #customFooter>
            <a-button @click.stop="handleClose">取消</a-button>
            <custom-portal-target :name="portalTarget" :slim="true" />
        </template>
    </custom-modal>
</template>
<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import CustomPortalTarget from '@components/CustomPortalVue/PortalTarget'

import ModalContent, { PORTAL_KEY } from './Content'

export default {
    name: 'InitiateFillingModal',
    components: {
        AButton,
        CustomModal,
        ModalContent,
        CustomPortalTarget
    },
    props: {
        id: String,
        visible: Boolean
    },
    data() {
        return {
            portalTarget: PORTAL_KEY
        }
    },
    methods: {
        handleClose() {
            this.$emit('on-close')
        },
        handleOk() {
            this.handleClose()
            this.$emit('on-refresh')
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.initiate-filling-modal {
    .ant-card {
        &.has-margin-top {
            margin-top: $space-sm;
        }
    }
}
</style>
