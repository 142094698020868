<template>
    <fragment>
        <information-panel
            title="申请信息"
            :columns="columns"
            :loading="loading.info"
            :data-source="marginQuotaHisVo"
        >
            <template #addonAfter>
                <template v-for="name in lowerLevelPanels">
                    <component
                        :is="name"
                        v-if="showLowerLevelPanel(name)"
                        :key="name"
                        :show-operation="false"
                        :data-source="getLowerLevelDataSource(name)"
                    />
                </template>
            </template>
        </information-panel>

        <custom-portal :to="portalTo">
            <popconfirm-button
                button-text="确认备案"
                button-type="primary"
                :loading="loading.confirm"
                :title="confirmFillingPopconfirmTile"
                @on-confirm="handleConfirmFilling(id)"
            />
        </custom-portal>
    </fragment>
</template>
<script>
import { Button as AButton } from 'ant-design-vue'
import Fragment from '@components/Fragment'
import PopconfirmButton from '@components/PopconfirmButton'
import InformationPanel from '@components/InformationPanel'
import CustomPortal from '@components/CustomPortalVue/Portal'

import FillingFailureMsg from './FillingFailureMsg'

import { CONFIRM_FILLING_POPCONFIRM_TITLE_FACTORY } from '@constant/enum'

import imageOrFileStringToArray from '@utils/imageOrFileStringToArray'

import {
    generateColumns,
    GUARANTEE_PANEL,
    CASH_PAYMENT_PANEL,
    DATA_SOURCE_FIELD_MAPPING,
    SHOW_CONTROL_FIELD_MAPPING
} from '../../config'

import confirmFillingService from '@service/marginManagement/marginPaymentDetail/confirmFillingService'
import getMarginPaymentInfoService from '@service/marginManagement/marginPaymentDetail/getMarginPaymentInfoInFillingService'

const LOWER_LEVEL_PANELS = [GUARANTEE_PANEL, CASH_PAYMENT_PANEL]

const LOWER_LEVEL_PANEL_FACTORY = {
    [GUARANTEE_PANEL]: () => import('../../GuaranteePanel'),
    [CASH_PAYMENT_PANEL]: () => import('../../CashPaymentPanel')
}

export const PORTAL_KEY = 'InitiateFillingModal'

export default {
    name: 'ModalContent',
    components: {
        AButton,
        Fragment,
        CustomPortal,
        InformationPanel,
        FillingFailureMsg,
        PopconfirmButton,
        ...LOWER_LEVEL_PANEL_FACTORY
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            portalTo: PORTAL_KEY,

            loading: {
                info: false,
                confirm: false
            },
            marginPaymentInfo: {}
        }
    },
    computed: {
        // 基础信息字段配置
        columns() {
            return generateColumns(this.marginPaymentInfo)
        },
        // 缴纳方式启用/停用备案审批
        autoReport() {
            return this.marginPaymentInfo.autoReport
        },
        // 基础信息数据
        marginQuotaHisVo() {
            return this.marginPaymentInfo.marginQuotaHisVO || {}
        },
        // 需要展示的二级面板组件名称集
        lowerLevelPanels() {
            return LOWER_LEVEL_PANELS.filter(name => this.showLowerLevelPanel(name))
        },
        // 确认备案的二次确认提示文案
        confirmFillingPopconfirmTile() {
            return CONFIRM_FILLING_POPCONFIRM_TITLE_FACTORY[this.autoReport]
        }
    },
    watch: {
        id: {
            handler(id) {
                this.getMarginPaymentInfo(id)
            },
            immediate: true
        }
    },
    methods: {
        // 获取申请（保证金缴纳）信息
        async getMarginPaymentInfo(id) {
            try {
                this.$set(this.loading, 'info', true)

                // eslint-disable-next-line
                this.marginPaymentInfo = await getMarginPaymentInfoService({ id }) || {}
            } catch (e) {
                this.$message.warning(e.message)
            } finally {
                this.$set(this.loading, 'info', false)
            }
        },
        // 确认备案
        async handleConfirmFilling(id) {
            try {
                this.$set(this.loading, 'confirm', true)

                // eslint-disable-next-line
                await confirmFillingService({ bizId: id })

                this.$emit('on-ok')
                this.$message.success('操作成功')
            } catch (e) {
                this.handleWarningFuilreMsg(e.message)
            } finally {
                this.$set(this.loading, 'confirm', false)
            }
        },
        // 提示失败信息
        handleWarningFuilreMsg(failureMsg) {
            const failureMsgs = imageOrFileStringToArray(failureMsg)

            if (!failureMsgs || failureMsgs.length === 0) {
                this.$message.warning(failureMsg)
                return
            }

            this.$warning({
                title: '校验失败',
                content: h => h(FillingFailureMsg, {
                    props: {
                        failureMsgs
                    }
                })
            })
        },
        /**
         * 获取 name 指定的二级面板组件所需源数据
         *
         * @param {string} name 二级面板组件名称
         */
        getLowerLevelDataSource(name) {
            return this.marginPaymentInfo[DATA_SOURCE_FIELD_MAPPING[name]] || {}
        },
        /**
         * 是否显示 name 指定的二级面板组件
         *
         * @param {string} name 二级面板组件名称
         */
        showLowerLevelPanel(name) {
            return this.marginPaymentInfo[SHOW_CONTROL_FIELD_MAPPING[name]]
        }
    }
}
</script>
